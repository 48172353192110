import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { GetGraphImagePath, GetGraphUrl } from 'src/components/Seo/helpers';

type MetaProps = JSX.IntrinsicElements['meta'];

interface Props {
  description?: string;
  lang?: string;
  image?: any;
  meta?: MetaProps[];
  title: string;
  withTitlePrefix?: boolean;
}

const SEO: React.FC<Props> = ({
  description = '',
  lang = 'lt',
  meta = [],
  title,
  image,
  withTitlePrefix,
}) => {
  const { site, placeholder } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        placeholder: file(relativePath: { eq: "piligrimo-keliones.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 1200)
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata?.description;
  const graphImage = GetGraphImagePath(image ? image : placeholder);
  const graphUrl = GetGraphUrl();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${title} ${
        withTitlePrefix ? `- ${site.siteMetadata?.title}` : ''
      }`}
      meta={(
        [
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:url`,
            content: graphUrl,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: graphImage,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ] as MetaProps[]
      ).concat(meta)}
    />
  );
};

export const PageSeoQuery = graphql`
  fragment YoastSeoPage on WpPage {
    seo {
      metaDesc
      title
      opengraphImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 1800)
          }
        }
      }
    }
  }
`;

export const ProductSeoQuery = graphql`
  fragment YoastSeoProduct on WpSimpleProduct {
    seo {
      metaDesc
      title
      opengraphImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 1800)
          }
        }
      }
    }
  }
`;

export const ProductCategorySeoQuery = graphql`
  fragment YoastSeoProductCategory on WpProductCategory {
    seo {
      metaDesc
      title
      opengraphImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 1800)
          }
        }
      }
    }
  }
`;

export default SEO;
